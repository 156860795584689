<template>
  <div>
    <v-container fluid>
      <!-- ---------------------------------------------------------------- -->
      <!-- Dados base de dados -->
      <!-- ---------------------------------------------------------------- -->

      <div v-if='item' class='mt-5'>
        <v-expansion-panels mandatory flat>
          <!-- ---------------------------------------------------------- -->
          <!-- List de usuários encontrados com o cpf e renach informado  -->
          <!-- ---------------------------------------------------------- -->
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon='mdi-menu-down'>
              <v-list-item>
                <v-list-item-avatar size='40' color='primary lighten-2'>
                  <v-icon dark>
                    mdi-account
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-tooltip bottom>
                      <template #activator='{ on }'>
                        <span v-on='on'>{{item.full_name}}</span>
                      </template>
                      <span>{{$t('CIVIL_NAME')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator='{ on }'>
                        <span v-on='on'>{{item.full_name}}</span>
                      </template>
                      <span>{{$t('CIVIL_NAME')}}</span>
                    </v-tooltip>

                    <v-tooltip v-if='item.serproError' bottom>
                      <template #activator='{ on }'>
                        <v-chip
                          outlined
                          color='error'
                          class='ml-2'
                          v-on='on'
                        >
                          {{item.serproError && item.serproError.returnCode ? item.serproError.returnCode : $t('GET_SERPRO_DATA_ERROR')}}
                        </v-chip>
                      </template>
                      {{item.serproError && item.serproError.message ? item.serproError.message : $t('GET_SERPRO_DATA_ERROR_DETAILS')}}
                    </v-tooltip>

                    <v-tooltip v-else bottom>
                      <template #activator='{ on }'>
                        <v-chip
                          outlined
                          :color='item.enrolled ? "success" : "warning"'
                          class='ml-2'
                          v-on='on'
                        >
                          {{item.enrolled ? $t('ENTRY_ENROLLED') : $t('ENTRY_NOT_ENROLLED')}}
                        </v-chip>
                      </template>
                      {{item.enrolled ? $t('ENTRY_ENROLLED') : $t('ENTRY_NOT_ENROLLED')}}
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-container fluid>
                <!-- ------------------------------------------------- -->
                <!-- Imagem do candidato -->
                <!-- ------------------------------------------------- -->
                <v-row no-gutters align='center' justify='center'>
                  <v-avatar v-if='item.face && item.face[0] && (item.face[0].segmented_data || item.face[0].data)' tile size='250' class='white'>
                    <v-img contain :src='`data:image/charset=UTF-8;png;base64,${item.face[0].segmented_data || item.face[0].data}`' />
                  </v-avatar>

                  <v-avatar v-else tile size='250' class='primary lighten-2'>
                    <v-icon x-large dark>
                      mdi-account
                    </v-icon>
                  </v-avatar>
                </v-row>

                <v-row no-gutters align='center' justify='center'>
                  <v-card-title class='pb-0'>
                    <v-tooltip bottom>
                      <template #activator='{ on }'>
                        <span v-on='on'>{{item.full_name}}</span>
                      </template>
                      <span>{{$t('CIVIL_NAME')}}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>

                <v-row no-gutters align='center' justify='center'>
                  <v-card-title class='py-0'>
                    <v-tooltip bottom>
                      <template #activator='{ on }'>
                        <span style='font-weight: 300' v-on='on'>{{item.social_name}}</span>
                      </template>
                      <span>{{$t('SOCIAL_NAME')}}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>

                <v-row no-gutters align='center' justify='center'>
                  <v-card-title class='pa-0 text-subtitle-1 font-weight-normal'>
                    <v-tooltip bottom>
                      <template #activator='{ on }'>
                        <span v-on='on'>{{$t('CREATED_AT', { value: formattedDateWithTime(item.created_at) })}}</span>
                      </template>
                      {{$t('CREATED_AT', { value: formattedDateWithTime(item.created_at) })}}
                    </v-tooltip>
                  </v-card-title>
                </v-row>

                <!-- ------------------------------------------------- -->
                <!-- Imagem do candidato -->
                <!-- ------------------------------------------------- -->
                <v-row no-gutters align='center' justify='center' class='mt-5'>
                  <v-tooltip bottom>
                    <template #activator='{ on }'>
                      <v-avatar size='75' :class='hasSentFaceProdam(item) ? "success mx-10" : "error mx-10"' v-on='on'>
                        <v-icon x-large dark>
                          mdi-emoticon-neutral-outline
                        </v-icon>
                      </v-avatar>
                    </template>
                    {{hasSentFaceProdam(item) ? $t('HAS_FACE_PRODAM') : $t('HAS_NOT_FACE_PRODAM')}}
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator='{ on }'>
                      <v-avatar size='75' :class='hasSentFingersProdam(item).count > 0 ? "success mx-10" : "error mx-10"' v-on='on'>
                        <span class='white--text title'>
                          {{hasSentFingersProdam(item).count}}
                        </span>
                      </v-avatar>
                    </template>
                    {{$t('PRODAM_FINGERS', { value: hasSentFingersProdam(item).count })}}
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator='{ on }'>
                      <v-avatar size='75' :class='hasSentFaceSerpro(item) ? "success mx-10" : "error mx-10"' v-on='on'>
                        <v-icon x-large dark>
                          mdi-badge-account-outline
                        </v-icon>
                      </v-avatar>
                    </template>
                    {{hasSentFaceSerpro(item) ? $t('HAS_FACE_SERPRO') : $t('HAS_NOT_FACE_SERPRO')}}
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator='{ on }'>
                      <v-avatar size='75' :class='hasSentSignatureSerpro(item) ? "success mx-10" : "error mx-10"' v-on='on'>
                        <v-icon x-large dark>
                          mdi-draw
                        </v-icon>
                      </v-avatar>
                    </template>
                    {{hasSentSignatureSerpro(item) ? $t('HAS_SIGNATURE_SERPRO') : $t('HAS_NOT_SIGNATURE_SERPRO')}}
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator='{ on }'>
                      <v-avatar size='75' :class='hasSentFingersSerpro(item).all ? "success mx-10" : "error mx-10"' v-on='on'>
                        <v-icon x-large dark>
                          mdi-fingerprint
                        </v-icon>
                      </v-avatar>
                    </template>
                    {{hasSentFingersSerpro(item).all ? $t('HAS_FINGERS_SERPRO') : $t('HAS_NOT_FINGERS_SERPRO')}}
                  </v-tooltip>
                </v-row>

                <v-row no-gutters align='center' justify='center' class='mt-5'>
                  <v-tabs v-model='tab' centered>
                    <v-tab>
                      {{$t('ENTRY_DATA')}}
                    </v-tab>
                    <v-tab>
                      {{$t('SIGNATURE')}}
                    </v-tab>
                    <v-tab>
                      {{$t('FINGERPRINTS')}}
                    </v-tab>
                    <v-tab>
                      {{$t('CAPTURE_INFO')}}
                    </v-tab>
                    <v-tab>
                      {{$t('SERVICE_TIMES')}}
                    </v-tab>
                    <v-tab>
                      {{$t('CHANGE_HISTORY')}}
                    </v-tab>
                  </v-tabs>
                </v-row>

                <v-row no-gutters align='center' justify='center' class='mt-10'>
                  <v-tabs-items v-model='tab'>
                    <!-- ------------------------------------------------- -->
                    <!-- Aba de dados do candidato -->
                    <!-- ------------------------------------------------- -->
                    <v-tab-item>
                      <!-- CPF e Identidade-->
                      <v-row>
                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-row class='mb-0'>
                                <v-col class='pb-0'>
                                  <v-list-item-title v-if='!item.editing_cpf'>
                                    {{applyCpfMask(item.cpf) || '---'}}
                                  </v-list-item-title>
                                  <v-text-field
                                    v-else
                                    :key='item.new_cpf'
                                    v-model='item.new_cpf'
                                    v-mask:bind='cpfMask'
                                    class='mb-1'
                                    hide-details
                                    dense
                                    append-icon='mdi-close'
                                    append-outer-icon='mdi-check'
                                    maxlength='14'
                                  >
                                    <template #append>
                                      <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                          <v-icon class='cursor-pointer' color='error' v-on='on' @click='toggleEdit("cpf")'>
                                            mdi-close
                                          </v-icon>
                                        </template>
                                        <span>{{ $t('CANCEL') }}</span>
                                      </v-tooltip>
                                    </template>
                                    <template #append-outer>
                                      <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                          <v-icon class='cursor-pointer' color='success' v-on='on' @click='saveEdit("cpf")'>
                                            mdi-check
                                          </v-icon>
                                        </template>
                                        <span>{{ $t('CONFIRM') }}</span>
                                      </v-tooltip>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col v-if='!item.editing_cpf' class='pb-0 pl-0 cursor-pointer' @click='toggleEdit("cpf")'>
                                  <v-icon class='mx-1' small :color='`${item.editing_cpf ? "error" : "primary"} lighten-2`'>
                                    {{!item.editing_cpf ? "mdi-pencil" : "mdi-close"}}
                                  </v-icon>
                                </v-col>
                              </v-row>
                              <v-list-item-subtitle>{{$t('CPF')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{formatRegisterId(item.register_id)}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('REGISTER_ID')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>

                      <!-- RENACH-->
                      <v-row>
                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-row class='mb-0'>
                                <v-col class='pb-0'>
                                  <v-list-item-title v-if='!item.editing_renach'>
                                    {{item.renach || '---'}}
                                  </v-list-item-title>
                                  <v-text-field
                                    v-else
                                    :key='item.new_renach'
                                    v-model='item.new_renach'
                                    v-mask:bind='renachMask'
                                    class='mb-1'
                                    hide-details
                                    dense
                                    append-icon='mdi-close'
                                    append-outer-icon='mdi-check'
                                    maxlength='11'
                                  >
                                    <template #append>
                                      <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                          <v-icon class='cursor-pointer' color='error' v-on='on' @click='toggleEdit("renach")'>
                                            mdi-close
                                          </v-icon>
                                        </template>
                                        <span>{{ $t('CANCEL') }}</span>
                                      </v-tooltip>
                                    </template>
                                    <template #append-outer>
                                      <v-tooltip bottom>
                                        <template #activator='{ on }'>
                                          <v-icon class='cursor-pointer' color='success' v-on='on' @click='saveEdit("renach")'>
                                            mdi-check
                                          </v-icon>
                                        </template>
                                        <span>{{ $t('CONFIRM') }}</span>
                                      </v-tooltip>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col v-if='!item.editing_renach' class='pb-0 pl-0 cursor-pointer' @click='toggleEdit("renach")'>
                                  <v-icon class='mx-1' small :color='`${item.editing_renach ? "error" : "primary"} lighten-2`'>
                                    {{!item.editing_renach ? "mdi-pencil" : "mdi-close"}}
                                  </v-icon>
                                </v-col>
                              </v-row>
                              <v-list-item-subtitle>{{$t('RENACH')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{item.last_renach || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('PREVIOUS_RENACH')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>

                      <!-- Data de nascimento e código de registro -->
                      <v-row>
                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{(item.date_of_birth && formattedDate(item.date_of_birth)) || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('BIRTH_DATE')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{item.register_code || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('REGISTER_CODE')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>

                      <!-- Nome da mãe e nome do pai -->
                      <v-row>
                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{item.mother_name || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('MOTHER_NAME')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{item.father_name || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('FATHER_NAME')}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{item.affective_affiliation1 || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('AFFECTIVE_AFFILIATION', { x: 1 })}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col sm='12' md='6' class='pa-1'>
                          <v-list-item two-line>
                            <v-list-item-avatar>
                              <v-icon small color='primary lighten-2'>
                                mdi-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{item.affective_affiliation2 || '---'}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('AFFECTIVE_AFFILIATION', { x: 2 })}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- ------------------------------------------------- -->
                    <!-- Aba de Assinatura -->
                    <!-- ------------------------------------------------- -->
                    <v-tab-item>
                      <v-row no-gutters align='center' justify='center' class='mt-5'>
                        <v-col sm='12' class='pa-1'>
                          <div class='caption font-weight-bold'>
                            {{$t('SIGNATURE')}}
                          </div>
                          <v-img v-if='item.signature && item.signature[0] && (item.signature[0].data || item.signature[0].segmented_data)' contain width='100%' height='250' :src='`data:image/charset=UTF-8;png;base64,${item.signature[0].data || item.signature[0].segmented_data}`' />
                          <div v-else class='caption font-weight-bold'>
                            {{$t('NO_SIGNATURE')}}
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-if='hasSentSignatureSerpro(item)' no-gutters align='center' justify='center' class='mt-5'>
                        <v-col sm='12' class='pa-1'>
                          <v-chip outlined color='primary' class='caption font-weight-bold'>
                            {{$t('SIGNATURE_SERPRO')}}
                          </v-chip>
                          <v-img contain width='100%' height='250' :src='`data:image/charset=UTF-8;png;base64,${item.serpro.assinatura.imagem.base64}`' />
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- ------------------------------------------------- -->
                    <!-- Aba de Biometrias -->
                    <!-- ------------------------------------------------- -->
                    <v-tab-item>
                      <v-row no-gutters align='center' justify='center' class='mt-5'>
                        <v-col sm='12' class='pa-1'>
                          <div class='caption font-weight-bold'>
                            {{$t('FINGERPRINTS')}}
                          </div>

                          <div v-if='item.finger && item.finger.length > 0'>
                            <div v-if='getBiometriesFingerprintRolled(item.finger).length > 0'>
                              <div class='caption font-weight-bold'>
                                {{$t('FINGERPRINTS_ROLLED')}}
                              </div>

                              <v-row no-gutters align='center' justify='center'>
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 0)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 1)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 2)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 3)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 4)' />
                              </v-row>

                              <v-row no-gutters align='center' justify='center'>
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 5)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 6)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 7)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 8)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintRolled(item.finger).find(f => f.finger_index === 9)' />
                              </v-row>
                            </div>

                            <div v-if='getBiometriesFingerprintFlat(item.finger).length > 0'>
                              <div class='caption font-weight-bold'>
                                {{$t('FINGERPRINTS_FLAT')}}
                              </div>

                              <v-row no-gutters align='center' justify='center'>
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 0)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 1)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 2)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 3)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 4)' />
                              </v-row>

                              <v-row no-gutters align='center' justify='center'>
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 5)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 6)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 7)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 8)' />
                                <entry-details-dialog-finger :finger='getBiometriesFingerprintFlat(item.finger).find(f => f.finger_index === 9)' />
                              </v-row>
                            </div>
                          </div>

                          <div v-else class='caption font-weight-bold'>
                            {{$t('NO_FINGERPRINTS')}}
                          </div>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <!-- ------------------------------------------------- -->
                    <!-- Aba de informações de quem realizou a captura e em qual estação -->
                    <!-- ------------------------------------------------- -->
                    <v-tab-item>
                      <div v-if='account'>
                        <v-row no-gutters class='mb-5'>
                          <span class='primary--text'>{{$t('RESPONSIBLE_FOR_THE_CAPTURE')}}</span>
                        </v-row>
                        <v-row>
                          <v-col sm='12' md='6' class='pa-1'>
                            <v-list-item two-line>
                              <v-list-item-avatar>
                                <v-icon small color='primary lighten-2'>
                                  mdi-circle
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{account.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('NAME')}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col sm='12' md='6' class='pa-1'>
                            <v-list-item two-line>
                              <v-list-item-avatar>
                                <v-icon small color='primary lighten-2'>
                                  mdi-circle
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{account.email || '---'}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('EMAIL')}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if='item.station'>
                        <v-divider class='my-5' />
                        <v-row no-gutters class='mb-5'>
                          <span class='primary--text'>{{$t('STATION_DATA_INFO')}}</span>
                        </v-row>

                        <v-row>
                          <v-col v-if='JSON.parse(item.station).id' sm='12' md='6' class='pa-1'>
                            <v-list-item two-line>
                              <v-list-item-avatar>
                                <v-icon small color='primary lighten-2'>
                                  mdi-circle
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{JSON.parse(item.station).id}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('ID')}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col v-if='JSON.parse(item.station).name' sm='12' md='6' class='pa-1'>
                            <v-list-item two-line>
                              <v-list-item-avatar>
                                <v-icon small color='primary lighten-2'>
                                  mdi-circle
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{JSON.parse(item.station).name}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('NAME')}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col v-if='JSON.parse(item.station).group' sm='12' md='6' class='pa-1'>
                            <v-list-item two-line>
                              <v-list-item-avatar>
                                <v-icon small color='primary lighten-2'>
                                  mdi-circle
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{JSON.parse(item.station).group}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('GROUP')}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col v-if='JSON.parse(item.station).details' sm='12' md='6' class='pa-1'>
                            <v-list-item two-line>
                              <v-list-item-avatar>
                                <v-icon small color='primary lighten-2'>
                                  mdi-circle
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{JSON.parse(item.station).details}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('DETAILS')}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if='!account && !item.station'>
                        {{$t('NO_DATA')}}
                      </div>
                    </v-tab-item>
                    <!-- ------------------------------------------------- -->
                    <!-- Aba de TMA -->
                    <!-- ------------------------------------------------- -->
                    <v-tab-item>
                      <div v-if='item.serviceTimes.length > 0'>
                        <v-row>
                          <v-tooltip v-for='(serviceTimeKey, stIndex) in Object.keys(item.serviceTimes[0])' :key='stIndex' top>
                            <template #activator='{ on }'>
                              <v-col class='d-grid' align='center' v-on='on'>
                                <v-icon large color='primary lighten-2' style='width: 100%'>
                                  {{serviceTimeIcon(serviceTimeKey)}}
                                </v-icon>
                                <v-icon small color='primary lighten-2'>
                                  mdi-clock-time-two-outline
                                </v-icon>
                                <span> {{formatServiceTime(item.serviceTimes[0][serviceTimeKey])}} </span>
                              </v-col>
                            </template>
                            {{$t(serviceTimeKey.toUpperCase())}}
                          </v-tooltip>
                        </v-row>
                        <v-row style='color: #BDBDBD' no-gutters align='center' justify='center'>
                          {{ $t('SHOWN_TIME_FORMAT') }}
                        </v-row>
                      </div>
                      <div v-else>
                        <v-row style='color: #BDBDBD' no-gutters align='center' justify='center'>
                          {{ $t('NO_SERVICE_TIME') }}
                        </v-row>
                      </div>
                    </v-tab-item>
                    <!-- ------------------------------------------------- -->
                    <!-- Aba de Histórico de alterações -->
                    <!-- ------------------------------------------------- -->
                    <v-tab-item>
                      <div v-if='item.personLogs && item.personLogs.length > 0'>
                        <v-list two-line disabled>
                          <v-list-item-group multiple>
                            <template v-for='(personLog, plIndex) in item.personLogs'>
                              <v-list-item :key='personLog.id'>
                                <v-list-item-content>
                                  <v-list-item-title v-text='personLog.username' />
                                  <v-list-item-subtitle class='text--primary' v-text='personLog.changeInfo' />
                                  <v-list-item-subtitle style='white-space: normal' v-text='`${$t("MOTIVE")}: ${personLog.motive}`' />
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-list-item-action-text v-text='formattedDateWithTime(personLog.created_at)' />
                                </v-list-item-action>
                              </v-list-item>

                              <v-divider v-if='plIndex < item.personLogs.length - 1' :key='`${plIndex}_${personLog.id}`' />
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </div>
                      <div v-else>
                        <v-row style='color: #BDBDBD' no-gutters align='center' justify='center'>
                          {{ $t('NO_INFO_CHANGE') }}
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
    <v-dialog v-model='item.motiveDialog' max-width='30%' persistent style='z-index: 999999'>
      <v-container class='pa-0'>
        <v-card class='px-2 py-5'>
          <v-textarea
            v-model='item.changeMotive'
            filled
            outlined
            counter='400'
            :placeholder='$t("INFO_CHANGE_MOTIVE")'
            @keydown='item.changeMotive = item.changeMotive ? item.changeMotive.substr(0, 399) : ""'
          />
          <v-row class='pb-1 mt-1 mr-1 justify-end'>
            <v-btn small class='mr-2' @click='closeMotiveDialog'>
              {{ $t('CANCEL') }}
            </v-btn>
            <v-btn small color='success' @click='submitInfoChange'>
              {{ $t('CONFIRM') }}
            </v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
  /* eslint-disable camelcase */
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mask } from 'vue-the-mask';
  import { mapGetters } from 'vuex';

  import { cpfMask } from '../../util/mask';
  import { cpfValidate } from '../../util/validation/cpf';
  import { showError, showSuccess } from '../../util/notification';

  export default {
    name: 'EntryDetails',
    directives: {
      mask: mask,
    },
    components: {
      EntryDetailsDialogFinger: () => import('./EntryDetailsDialogFinger'),
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    data: function () {
      return {
        tab: 0,
        account: undefined,
        cpfMask: '###.###.###-##',
        renachMask: 'AA#########',
        fieldToUpdate: '',
      };
    },
    mounted: function () {
      this.getCreatorData();
    },
    methods: {
      formatNewValueData: function (value) {
        if (this.fieldToUpdate === 'cpf') {
          return value.replace(/\D/g, '');
        }
        return value;
      },
      closeMotiveDialog: function () {
        this.item.motiveDialog = false;
        this.item.changeMotive = '';
        this.item[`editing_${this.fieldToUpdate}`] = false;
        this.fieldToUpdate = undefined;
        this.$forceUpdate();
      },
      submitInfoChange: async function () {
        const updatePersonObj = {
          id: this.item.id,
          action: 'MUTATION',
          motive: this.item.changeMotive,
          fieldToUpdate: this.fieldToUpdate,
          oldValue: this.item[this.fieldToUpdate],
          newValue: this.formatNewValueData(this.item[`new_${this.fieldToUpdate}`]),
          userId: this.getUser.account.id,
        };

        updatePersonObj[this.fieldToUpdate] = this.item[`new_${this.fieldToUpdate}`];

        try {
          await axios({
            url: '/person',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'PUT',
            responseType: 'json',
            withCredentials: true,
            data: updatePersonObj,
          }).then(() => {
            showSuccess({ message: this.$t('RECORD_UPDATED_SUCCESSFULLY') });
            this.item[this.fieldToUpdate] = this.item[`new_${this.fieldToUpdate}`];
            this.closeMotiveDialog();
            this.$emit('info-change', this.item.cpf.replace(/\D/g, ''));
            setTimeout(() => {
              this.$forceUpdate();
            }, 1000);
          });
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      applyCpfMask: function (cpf) {
        return cpfMask(cpf);
      },
      saveEdit: async function (fieldName) {
        const newValueField = `new_${fieldName}`;
        let oldValue = null;
        let newValue = null;

        if (!this.item[newValueField]) {
          showError({ message: this.$t('VALUE_CANT_BE_EMPTY') });
          return;
        }

        if (fieldName === 'cpf') {
          if (!cpfValidate(this.item[newValueField])) {
            showError({ message: this.$t('INVALID_CPF') });
            return;
          }
          oldValue = cpfMask(this.item[fieldName]);
          newValue = cpfMask(this.item[newValueField]);
        }

        if (fieldName === 'renach') {
          oldValue = this.item[fieldName];
          newValue = this.item[newValueField];
        }

        const result = await Swal.fire({
          title: this.$t('WARNING'),
          text: this.$t('REALLY_WANT_TO_CHANGE_INFO', { field: fieldName.toUpperCase(), oldValue, newValue }),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM_INFO_CHANGE'),
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        });

        if (!result.value) {
          return;
        }

        this.fieldToUpdate = fieldName;

        this.retryCommit(this.item);

        this.item.motiveDialog = true;
        this.$forceUpdate();
      },
      retryCommit: async function (item) {
        try {
          await axios({
            url: '/entry-commit',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              id: item.id,
            },
          });

          this.getData();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      toggleEdit: function (fieldName) {
        const newValueField = `new_${fieldName}`;
        this.item[newValueField] = this.item[fieldName];
        this.item[`editing_${fieldName}`] = !this.item[`editing_${fieldName}`];
        this.$forceUpdate();
      },
      formatRegisterId: function (registerId) {
        if (registerId) {
          return `[${registerId[0]}] ${registerId.substring(1, registerId.length)}`;
        }
        return '---';
      },
      formatServiceTime: function (time) {
        return new Date(time).toISOString().slice(11, 19);
      },
      serviceTimeIcon: function (serviceTimeType) {
        switch (serviceTimeType) {
          case 'enrollment_form': return 'mdi-text-account';
          case 'face_capture':
          case 'mugshot_capture': return 'mdi-badge-account-outline';
          case 'fingerprint_capture': return 'mdi-fingerprint';
          case 'signature_capture': return 'mdi-draw';
          default: return 'mdi-badge-account-outline';
        }
      },
      formattedDate: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy', { locale: ptBr });
      },
      formattedDateWithTime: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy HH:mm:ss', { locale: ptBr });
      },
      clearData: function () {
        this.entry = {
          cpf: undefined,
          renach: undefined,
        };
      },
      getBiometriesFingerprintRolled: function (fingers) {
        return fingers.filter((i) => i.capture_type === 2);
      },
      getBiometriesFingerprintFlat: function (fingers) {
        return fingers.filter((i) => i.capture_type !== 2);
      },
      hasSentFaceSerpro: function (item) {
        return item.serpro && item.serpro.retrato && !!item.serpro.retrato.imagem.base64;
      },
      hasSentSignatureSerpro: function (item) {
        return item.serpro && item.serpro.assinatura && !!item.serpro.assinatura.imagem.base64;
      },
      hasSentFingersSerpro: function (item) {
        if (!item.serpro) {
          return false;
        }

        const {
          anularDireito,
          anularEsquerdo,
          indicadorDireito,
          indicadorEsquerdo,
          medioDireito,
          medioEsquerdo,
          minimoDireito,
          minimoEsquerdo,
          polegarDireito,
          polegarEsquerdo,
        } = item.serpro;

        const hasRightRing = anularDireito && ((anularDireito.imagem && !!anularDireito.imagem.base64) || !!anularDireito.motivoAusencia);
        const hasLeftRing = anularEsquerdo && ((anularEsquerdo.imagem && !!anularEsquerdo.imagem.base64) || !!anularEsquerdo.motivoAusencia);
        const hasRightIndex = indicadorDireito && ((indicadorDireito.imagem && !!indicadorDireito.imagem.base64) || !!indicadorDireito.motivoAusencia);
        const hasLeftIndex = indicadorEsquerdo && ((indicadorEsquerdo.imagem && !!indicadorEsquerdo.imagem.base64) || !!indicadorEsquerdo.motivoAusencia);
        const hasRightMiddle = medioDireito && ((medioDireito.imagem && !!medioDireito.imagem.base64) || !!medioDireito.motivoAusencia);
        const hasLeftMiddle = medioEsquerdo && ((medioEsquerdo.imagem && !!medioEsquerdo.imagem.base64) || !!medioEsquerdo.motivoAusencia);
        const hasRightPinky = minimoDireito && ((minimoDireito.imagem && !!minimoDireito.imagem.base64) || !!minimoDireito.motivoAusencia);
        const hasLeftPinky = minimoEsquerdo && ((minimoEsquerdo.imagem && !!minimoEsquerdo.imagem.base64) || !!minimoEsquerdo.motivoAusencia);
        const hasRightThumb = polegarDireito && ((polegarDireito.imagem && !!polegarDireito.imagem.base64) || !!polegarDireito.motivoAusencia);
        const hasLeftThumb = polegarEsquerdo && ((polegarEsquerdo.imagem && !!polegarEsquerdo.imagem.base64) || !!polegarEsquerdo.motivoAusencia);

        return {
          all: hasRightRing && hasLeftRing && hasRightIndex && hasLeftIndex && hasRightMiddle && hasLeftMiddle && hasRightPinky && hasLeftPinky && hasRightThumb && hasLeftThumb,
          hasRightRing: hasRightRing,
          hasLeftRing: hasLeftRing,
          hasRightIndex: hasRightIndex,
          hasLeftIndex: hasLeftIndex,
          hasRightMiddle: hasRightMiddle,
          hasLeftMiddle: hasLeftMiddle,
          hasRightPinky: hasRightPinky,
          hasLeftPinky: hasLeftPinky,
          hasRightThumb: hasRightThumb,
          hasLeftThumb: hasLeftThumb,
        };
      },
      hasSentFaceProdam: function (item) {
        return item.prodam && item.prodam.imagens && !!item.prodam.imagens.foto;
      },
      hasSentFingersProdam: function (item) {
        const hasRightRing = item.prodam && item.prodam.imagens && !!item.prodam.imagens.anelarDireito;
        const hasLeftRing = item.prodam && item.prodam.imagens && !!item.prodam.imagens.anelarEsquerdo;
        const hasRightIndex = item.prodam && item.prodam.imagens && !!item.prodam.imagens.indicadorDireito;
        const hasLeftIndex = item.prodam && item.prodam.imagens && !!item.prodam.imagens.indicadorEsquerdo;
        const hasRightMiddle = item.prodam && item.prodam.imagens && !!item.prodam.imagens.medioDireito;
        const hasLeftMiddle = item.prodam && item.prodam.imagens && !!item.prodam.imagens.medioEsquerdo;
        const hasRightPinky = item.prodam && item.prodam.imagens && !!item.prodam.imagens.minimoDireito;
        const hasLeftPinky = item.prodam && item.prodam.imagens && !!item.prodam.imagens.minimoEsquerdo;
        const hasRightThumb = item.prodam && item.prodam.imagens && !!item.prodam.imagens.polegarDireito;
        const hasLeftThumb = item.prodam && item.prodam.imagens && !!item.prodam.imagens.polegarEsquerdo;

        return {
          all: hasRightRing && hasLeftRing && hasRightIndex && hasLeftIndex && hasRightMiddle && hasLeftMiddle && hasRightPinky && hasLeftPinky && hasRightThumb && hasLeftThumb,
          hasRightRing: hasRightRing,
          hasLeftRing: hasLeftRing,
          hasRightIndex: hasRightIndex,
          hasLeftIndex: hasLeftIndex,
          hasRightMiddle: hasRightMiddle,
          hasLeftMiddle: hasLeftMiddle,
          hasRightPinky: hasRightPinky,
          hasLeftPinky: hasLeftPinky,
          hasRightThumb: hasRightThumb,
          hasLeftThumb: hasLeftThumb,
          count: [hasRightRing, hasLeftRing, hasRightIndex, hasLeftIndex, hasRightMiddle, hasLeftMiddle, hasRightPinky, hasLeftPinky, hasRightThumb, hasLeftThumb].filter(Boolean).length,
        };
      },
      getCreatorData: async function () {
        if (!this.item.creator_id) {
          return;
        }

        const { data } = await axios({
          url: '/account-get-by-id',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            accountId: this.item.creator_id,
          },
        });

        this.account = data && data.account;
      },
    },
  };
</script>

<style>
  .cursor-pointer:hover {
    cursor: pointer;
  }

  .v-input__append-outer {
    margin-top: -1px;
  }
</style>
