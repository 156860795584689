function cpfMask (cpf: string): string {
  if (!cpf) {
    return '';
  }
  cpf = cpf.replace(/\D/g, '');
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
}

export {
  cpfMask,
};
